<template >
  <div :style="cardStyles">
    <div class="left-body" :style="cardStyles">
      <div class="pr-1">
        <div>
          <b-form-group v-if="listLanguages" label="Chọn ngôn ngữ">
            <v-select
                v-model="LangOfReport"
                label="Description"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listLanguages"
                :multiple="false"
                style="min-width: 150px"
                :clearable="false"
                @input="changeLanguage(LangOfReport)"
            >
                <template #selected-option="{ WordTranslate, LangCode }">
                <b-img
                style="margin-right:5px"
                :src="require(`@/assets/images/flags/${WordTranslate}.svg`)"
                width="22px"
            />
                {{ LangCode }}
                </template>
                <template v-slot:option="item">
                <b-img
                style="margin-right:5px"
                :src="require(`@/assets/images/flags/${item.WordTranslate}.svg`)"
                width="22px"
            />
                {{ item.LangCode }}
                </template>
              </v-select>
          </b-form-group>
        </div>
        <div>
          <b-form-group label="Tìm kiếm tên">
            <v-select
              v-model="keyWord"
              label="GuestName"
              :options="listBDC"
              :reduce="(GuestName) => GuestName.BDC"
              :clearable="true"
              @input="printDetail(keyWord)"
              placeholder="Tìm kiếm tên"
            />
          </b-form-group>
        </div>
        
        
        <div class="d-flex mt-1 mb-1 ml-2">
          <b-form-checkbox
          style="width:15%;margin-top:auto;margin-bottom:auto"
          v-model="isShowDetailService"
          @input="ShowDetailService"
        />
        <span style="width:85%">Xem chi tiết dịch vụ thu hộ từ khu kinh doanh</span>
        </div>
        <div style="overflow-y: auto; height: 760px;">
          <div v-if="!isInvoiceOnePerson">
            <b-button
              class="print"
              variant="primary"
              @click="printAll()"
              style="margin-top: 10px"
              >{{ $t("golf_Invoice_all") }}</b-button
            >
          </div>
          <div v-if="!isInvoiceOnePerson">
            <b-button
              class="print"
              variant="primary"
              @click="printSum()"
              style="margin-top: 10px"
              >{{ $t("golf_Invoice_total") }}</b-button
            >
          </div>
          <div v-for="(item, index) in listBDC" :key="index">
            <b-button
              class="print"
              variant="primary"
              @click="printDetail(item.BDC)"
              style="margin-top: 10px; width: 80%"
            >
              <div style="padding: 5px 0 5px 0">
                {{ item.BDC }} - {{ item.GuestName }}
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div id="iv01" :style="cardStyles">loading...</div>
  </div>
</template>

<script>
// var urlGlobal = null
//   setTimeout(() => {
//     document.getElementById('haiaa').addEventListener('click', printPDFinvoice(urlGlobal))
//   },3000)
//   function printPDFinvoice(url) {
//     console.log(71, url)
//     const iframe = document.createElement('iframe');
//     iframe.src = url;
//     iframe.id = 'print_pdf';
//     iframe.name = 'print_pdf';
//     // iframe.style.display = 'none'
//     iframe.onload = () => {
//       iframe.contentWindow.addEventListener('afterprint', (evt) => {
//         console.log("printed");
//       });
//       window.frames['print_pdf'].focus()
//       window.frames['print_pdf'].print()
//     }
//     document.body.appendChild(iframe)
//   }
    import { API_RIV01, REPORT_URL } from "@/api/constant";
    import { booking } from "@/api/booking";
    import { localize } from 'vee-validate'
    import { commonServices } from '@/api/common-services'
    //import jQuery from "jquery";
    import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
    //window.$ = jQuery;
    export default {
        name: "ReportViewer",
        data() {
            return {
                msg: "Print invoice",
                PaymentId: null,
                obj: null,
                objdeltai: null,
                objdeltai2: null,
                objdeltai3: null,
                objdeltai4: null,
                json: null,
                isSelectBDC: false,
                listBDC: [],
                response: null,
                keyWord: null,
                pLang: null,
                pToken: null,
                pTimeZone: null,
                pTimeZoneOffset: null,
                pPaymentId: null,
                pIndex: 0,
                pIsGetAll: true,
                pUrl: null,
                fileName: null,
                isShowDetailService: true,
                isInvoiceOnePerson: false,
                isPrintDirectbyPDF: false,
                pdf: null,
                LangOfReport: null,
                listLanguages: null,
                windowHeight: 0,
                heightTable: 400,
                cardStyles: {},
            };
        },
        async created() {
          await this.getListLanguages()
          this.api_RIV01();
          await this.getParam()
          
        },
        watch: {
            pUrl() {
                this.getInvoice()
            },
            windowHeight(value) {
                this.heightTable = value - 100 + 'px'
                this.$set(this.cardStyles, 'maxHeight', `${this.heightTable}`)
            }
        },
        mounted() {
          this.windowHeight = window.innerHeight
        },
        methods: {
          async getListLanguages() {
            await commonServices.getLanguages()
                .then(res => {
                    if (res) {
                        this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
                        this.LangOfReport =  this.listLanguages.find(l => l.LangId == this.$i18n.locale)
                    }
                })
          },
            print() {
                this.json = this.obj;
                this.resetInvoice()
            },
            printAll() {
                this.pIsGetAll = true
                this.resetInvoice()
            },
            printSum() {
                this.pIndex = 0
                this.pIsGetAll = false
                this.resetInvoice()
            },
            printDetail(BDCselect) {
                for (let i = 1; i < this.response.Data.Invoice.length; i++) {
                    if (this.response.Data.Invoice[i].InfoInvoice.PayForBDC == BDCselect) {
                        this.pIndex = i
                        this.pIsGetAll = false
                    }
                }
                this.resetInvoice()
            },
            //1. Get thong tin payment
            async api_RIV01() {
                this.PaymentId = this.$route.params.id * 1;
                this.isPrintDirectbyPDF = this.$route.query.pdf;
                const body = {
                    PaymentId: this.PaymentId,
                    Type: "ALL",
                };
                await booking.api_RIV01(body).then((response) => {
                    if (response.Data.PayFor.length == 1) {
                        this.isInvoiceOnePerson = true
                        this.pIsGetAll = false
                    }
                    if (response.Data.Invoice[0].ExtenalInvoiceProduct.length == 0) {
                        this.isShowDetailService = false
                    }
                    this.listBDC = response.Data.PayFor;
                    this.response = response;
                });
            },
            //3. Get file name
            async api_RCOM01() {
                const body = {
                    KeyGroup: "REPORT_CONFIG",
                    LangOfData: String(this.LangOfReport.LangId)
                }
                await commonServices.getCommon(body).then(response => {
                    this.fileName = response.Data.find(x => x.KeyCode == "IV01_REPORT_FILE_NAME").KeyValue
                })
            },
            //4. Get url
            async api_RCF00() {
                await commonServices.api_RCF00().then(response => {
                    this.pUrl = `${response.Data.find(x => x.SettingCode === 'SITE_API_URL').SettingValue}/api${API_RIV01}`
                })
            },
            //2. Get config
            getParam() {
                const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
                this.pLang = localStorage.getItem('systemLanguage') || '1000000'
                this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
                this.pTimeZone = localStorage.getItem('timeZone')
                this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
                this.pPaymentId = this.$route.params.id * 1
                this.api_RCOM01().then(() => {
                    this.api_RCF00()
                })
            },
            getInvoice() {
                    $("#iv01").telerik_ReportViewer({
                        serviceUrl: REPORT_URL,
                        reportSource: {
                            report: this.fileName,
                            parameters: { pIsGetAll: this.pIsGetAll, pInvoiceIndex: this.pIndex, pToken: this.pToken, pPaymentId: this.pPaymentId, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName },
                        },
                        viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
                        scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
                        scale: 1.0,
                        sendEmail: { enabled: false },
                    });
            },
            resetInvoice() {
                var reportViewer = $("#iv01").data("telerik_ReportViewer");
                reportViewer.reportSource({
                    report: this.fileName,
                    parameters: { pIsGetAll: this.pIsGetAll, pInvoiceIndex: this.pIndex, pToken: this.pToken, pPaymentId: this.pPaymentId, pUrl: this.pUrl, pLang: this.LangOfReport.LangId, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset, isShowDetailService: this.isShowDetailService, pUser: JSON.parse(localStorage.getItem('userData')).userName },
                });
            },
            ShowDetailService() {
                this.resetInvoice()
            },
            async changeLanguage() {
              await this.api_RCOM01()
              await this.resetInvoice()
            }
        },
    };
</script>

<style lang="scss" >
#iv01 {
  position: absolute;
  top: 50px;
  bottom: 10px;
  right: 0;
  overflow: hidden;
  clear: both;
  width: 85%;
}
.print {
  margin: 0 10px 0 10px;
}
.left-body {
  width: 15%;
}.trv-report-page{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>